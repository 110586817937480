import { filterByValue, fetchLastinsertedArrayFirst } from '../../library/utility';

const initialState = {
    deviceFilters : [],
    deviceFiltersCSV:[],
    fetching: false, 
    fetched: false, 
    deviceFilterDeleteErrorMessage : null,
    csvRequestId:null,
    isRefresh:false,
    refreshFilterData:{},
    refereshStatus:null,
    isRunVal:false,
    isSaveEnabledSt:true,
    size : 0,
    start : 0,
    end : 10,
    pageNumber : 1,
    totalSize:0,
}

export default function reducer (state = initialState, action) {
    switch(action.type) {
        case 'FETCH_DEVICE_FILTERS':{
            return {...state, fetching: true}
        }
        case 'FETCH_DEVICE_FILTERS_SUCCESS':{
            const deviceFilters = fetchLastinsertedArrayFirst(action.payload.data.data.data);
            const total = action.payload.data.data;
            localStorage.setItem("deviceFilters", JSON.stringify(deviceFilters));
            return { ...state, 
                fetching: false, 
                fetched: true,
                deviceFilters : deviceFilters, 
                deviceFilterDeleteErrorMessage : null,
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
                totalSize: total.total_size
            }
        }

        case 'FETCH_DEVICE_CSV_FILTERS':{
            return {...state, fetching: true}
        }
        case 'FETCH_DEVICE_CSV_FILTERS_SUCCESS':{
            const deviceFiltersCSV = action.payload.data.data.data
            return { ...state, 
                fetching: false, 
                fetched: true,
                deviceFiltersCSV : deviceFiltersCSV, 
                deviceFilterDeleteErrorMessage : null 
            }
        }

        case 'FETCH_RESET_REFRESH':{
            return {
                ...state,
                isRefresh:false
            }
        }
        
        case 'FETCH_CSV_UPLOAD_SUCCESS':{

            const reqId = action.payload.data
            return {
                ...state,
                fetching: false,
                fetched: true,
                isRefresh:true,
                isRunVal:true,
                csvRequestId:reqId

            }
        }
        case 'FETCH_CSV_REFRESH_SUCCESS':{
            const refreshData = action.payload.data.data;
            const refreshFilterData = refreshData.device_filter
            const refereshStatus= refreshData.status_message
            var save;
            if(refreshData.status_message==="COMPLETED"){
                 save =false;
            }else{
                save = true;
            }
 
            return {
                ...state,
                fetching: false,
                fetched: true,
                isRefresh:true,
                refreshFilterData:refreshFilterData,
                refereshStatus:refereshStatus,
                isSaveEnabledSt:save
 
            }
        }
        case 'RESET_DISABLERS_SAVE':{
            return {
                ...state,
                isSaveEnabledSt:true
            }
        }
        case 'FETCH_DEVICE_FILTERS_FAILURE':{
            return {...state, fetching: false, error: action.payload }
        }
        case 'FETCH_DEVICE_CSV_FILTERS_FAILURE':{
            return {...state, fetching: false, error: action.payload }
        }
        case "FETCH_SEARCH_DEVICE_FILTERS_FAILURE": {
            return { ...state,  deviceFilterDeleteErrorMessage : action.payload }
        }
        case "FETCH_SEARCH_DEVICE_FILTERS_SUCCESS": {
            const deviceFilter= action.payload.request.deviceFilterSearch;
            const devices = action.payload.response.data.data;
            let deviceFilters = fetchLastinsertedArrayFirst(devices);
            if(deviceFilter !== undefined && deviceFilter !== null && deviceFilter !== "") {
                //deviceFilters = devices.filter((item) => item.filter_name.toLowerCase().includes(deviceFilter.toLowerCase().replace('*','')) );
                deviceFilters = filterByValue(devices, deviceFilter.replace('*',''));
            }
            localStorage.setItem("deviceFilters", JSON.stringify(deviceFilters));
            return { ...state, deviceFilters : deviceFilters, deviceFilterDeleteErrorMessage : null }
        }
        case 'DELETE_DEVICE_FILTER_SUCCESS' : {
            const filterID = action.payload.filterID;
            let index = -1;
            state.deviceFilters.find(function(item, i){
                if(item.filter_id == filterID){
                    index = i;
                }
            });
            const updatedDeviceFilters = [...state.deviceFilters];
            updatedDeviceFilters.splice(index, 1);
            localStorage.setItem("deviceFilters", JSON.stringify(updatedDeviceFilters));
            return { ...state, deviceFilters : updatedDeviceFilters, deviceFilterDeleteErrorMessage : null }   
        }    
        case 'REFRESH_DEVICE_FILTERS' : {
            return { ...state, deviceFilters : action.payload, deviceFilterDeleteErrorMessage : null }
        }
        case 'DELETE_DEVICE_FILTER_FAILURE' : {
            return { ...state, deviceFilterDeleteErrorMessage : action.payload.data.data.error_message }  
        }
        case 'CLEAR_DEVICE_FILTERS' : {
            localStorage.removeItem("deviceFilters");
            return { ...state, deviceFilters : [], deviceFilterDeleteErrorMessage : null }
        }
    }
    return state
}